

// Material Dashboard 2 React example components
import { Grid } from "@mui/material"
import LoadingImage from "../../assets/images/loading.svg";
import MDBox from "components/MDBox";
import useHttp from "helpers/hooks/useHttp";
import { useNavigate } from 'react-router-dom'
import { useEffect, useContext } from "react";
import { AuthContext } from '../../context/authContext';
import DownloadCsvErrorModal from "components/Modals/downloadCsvErrorModal";

// Data

const LoadingScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticated, setSpareAuth, sparefoot } = useContext(AuthContext);

  if (!isAuthenticated) {
    navigate('/sign-in');
  }

  const { fetchData, error, called } = useHttp({
    autoRun: false,
    method: 'get',
    url: '/sparefoot/download',
    headers: JSON.stringify({ accept: '*/*' }),
  })

  useEffect(() => {
    if (sparefoot) {
      fetchData()
    }
  }, [sparefoot])

  useEffect(() => {
    if (called) {
      const user = localStorage.getItem('user')
      if (user) {
        const { role } = JSON.parse(user)
        if (role === 'global_admin') navigate('/portfolios')
        else navigate('/dashboard')
      }
    }
  }, [called, navigate])

  useEffect(() => {
    if (error) {
      setSpareAuth(false)
    }
  }, [error])

  return (
    <Grid container style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px', height: '100%', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
          <MDBox component="img" src={LoadingImage} alt="Brand" width="80%" maxWidth="300px" />
          <p>Downloading Zip/City Bid Opportunities From SpareFoot...</p>
        </div>
      </div>
      { error && <DownloadCsvErrorModal modalOpen={!!error} onClose={() => setSpareAuth(false)} /> }
    </Grid>
  );
}

export default LoadingScreen;
