import React, { useState } from 'react'
import { Grid, ListItem } from "@mui/material";
import {
  Box
} from '@mui/material';
import dayjs from "dayjs";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc);
dayjs.extend(timezone);

const PortfolioEdit = ({ data, onSave }) => {
  const [portfolioName, setPortfolioName] = useState(data.portfolio_name)
  const [accountId, setAccountId] = useState(data.account_id)
  const [clv, setCLV] = useState(data?.clv)
  const [users, setUsers] = useState(data.users || [])
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')

  const addNewUser = () => {
    if (!fullName || !email) return
    users.push({
      fullName,
      email,
      portfolio_id: data.portfolio_id
    })
    setUsers([...users])
    setFullName('')
    setEmail('')
  }

  const saveData = () => {
    const payload = {
      ...data,
      portfolio_name: portfolioName,
      account_id: +accountId,
      clv: +clv,
      users,
    };
    onSave(payload)
  }

  return (
    <Box sx={{ fontSize: 14, marginTop: 3 }}>
      <Grid container rowSpacing={2}>
        <Grid item xs={2}>
          Portfolio ID
        </Grid>
        <Grid item xs={4}>
          {data.portfolio_id}
        </Grid>
        <Grid item xs={2}>
          Optimization Strategy
        </Grid>
        <Grid item xs={4}>
          {data.optimization_strategy
            ?.split('_')
            .map((seg) => seg.toUpperCase())
            .join(' ')}
        </Grid>
        <Grid item xs={2}>
          Last Optimized
        </Grid>
        <Grid item xs={4}>
          {data?.last_optimized ? dayjs.utc(data.last_optimized).local().format('MM/DD/YYYY, h:mm A') : ''}
        </Grid>
        <Grid item xs={2}>
          Last Submission
        </Grid>
        <Grid item xs={4}>
          {data?.last_submission ? dayjs.utc(data.last_submission).local().format('MM/DD/YYYY, h:mm A') : ''}
        </Grid>
        <Grid item xs={2}>
          Portfolio Name
        </Grid>
        <Grid item xs={10}>
          <MDInput value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} />
        </Grid>
        <Grid item xs={2}>
          Account ID
        </Grid>
        <Grid item xs={4}>
          <MDInput type="number" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
        </Grid>

        <Grid item xs={2}>
          CLV
        </Grid>
        <Grid item xs={4}>
          <MDInput type="number" value={clv} onChange={(e) => setCLV(e.target.value)} />
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={2}>
          Users
        </Grid>
        <Grid item xs={10}>
          <Grid container columnSpacing={2}>
            <Grid item xs={4}>
              <div style={{ paddingBottom: 12, fontWeight: 'bold' }}>Full Name</div>
            </Grid>
            <Grid item xs={8}>
              <div style={{ paddingBottom: 12, fontWeight: 'bold' }}>Email</div>
            </Grid>
          </Grid>
          {users?.map((user, index) => (
            <Grid key={index} container columnSpacing={2}>
              <Grid item xs={4}>
                <div style={{ paddingBottom: 12 }}>{user.fullName}</div>
              </Grid>
              <Grid item xs={8}>
                <div style={{ paddingBottom: 12 }}>{user.email}</div>
              </Grid>
            </Grid>
          ))}
          <Grid container columnSpacing={2}>
            <Grid item xs={4}>
              <MDInput
                fullWidth
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <MDButton variant="contained" color="success" onClick={addNewUser}>
                Add New User
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}>
        <MDButton sx={{ ml: 1 }} variant="outlined" color="error" onClick={saveData}>
          {!data.saving ? 'Save' : 'Saving...'}
        </MDButton>
      </Grid>
    </Box>
  )
}

export default PortfolioEdit