import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { Grid } from "@mui/material"
import MDBox from "components/MDBox"
import logo from '../../assets/images/logo.svg'
import { Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { AuthContext } from '../../context/authContext';
import axios from 'axios';
import PasswordResetModal from 'components/Modals/passwordResetModal';

const SignIn = () => {
  const navigate = useNavigate();
  const { login, isAuthenticated } = useContext(AuthContext);
  const [modal, setModal] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [loginError, setLoginError] = useState(false);

  if (isAuthenticated) {
    const user = localStorage.getItem('user')
    if (user) {
      const { role } = JSON.parse(user)
      if (role === 'global_admin') navigate('/portfolios')
      else navigate('/dashboard')
    }
  }

  const handleLogin = (e) => {
    e.preventDefault()
    const form = {
      email,
      password,
    }

    axios.post('/login', form).then((result) => {
      const { data } = result;
      const user = data.result;
      const token = user.accessToken;
      login(token, user, isRememberMe);
      if (user.role === 'global_admin') {
        navigate('/portfolios')
      } else if (user.sparefoot_u) {
        navigate('/loading');
      } else {
        navigate('/dashboard')
      }
    }).catch((error) => {
      setLoginError(true);
    })
  };

  const closeModal = () => {
    setModal(false)
  }

  return (
    <Grid container style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <PasswordResetModal modalOpen={modal} onClose={closeModal} />
      <Grid container xs={12} sm={6} style={{ borderRadius: '20px' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ borderRadius: { xs: '20px 20px 20px 20px', sm: '20px 20px 20px 20px', md: '20px 0px 0px 20px' } }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f9cdc7',
            padding: '80px 0 ',
          }}
        >
          <form onSubmit={handleLogin}>
            <Grid item style={{ width: '100%', maxWidth: '400px', padding: '0 20px' }}>
              <Typography color="error" variant="h3">
                Sign In
              </Typography>
              <Grid item pt={5}>
                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  required
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: '100%', outline: 'none', border: 'none', background: 'white', padding: '12px 25px', borderRadius: '999px', marginBottom: '15px', color: '#555' }}
                />
                <input
                  variant="standard"
                  type="password"
                  id="password"
                  placeholder="Password"
                  required
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ width: '100%', outline: 'none', border: 'none', background: 'white', padding: '12px 25px', borderRadius: '999px', marginBottom: '15px', color: '#555' }}
                />
              </Grid>
              <Grid
                item
                py={4}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <input type="checkbox" checked={isRememberMe} onChange={e => setIsRememberMe(e.target.checked)} /> <label for="remember"><Typography variant='h6'>Remember</Typography></label>
                </div>
                <Typography variant="h6" onClick={() => setModal(true)} sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}>
                  Forgot password?
                </Typography>
              </Grid>
              {loginError && <Typography pb={2} color="error" size="small">Invalid credentials. Please try again.</Typography>}
              <MDButton type="submit" variant="contained" color="error" fullWidth>
                Sign In
              </MDButton>
            </Grid>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
          style={{
            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            borderRadius: '0px 20px 20px 0px'
          }}
        >
          <MDBox component="img" src={logo} alt="Brand" width="80%" maxWidth="300px" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignIn
