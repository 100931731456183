import React, { useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";

const SelectGroup = ({ options, clickHandler, selected }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(selected)
  }, [selected])

  const handleChange = (changedValue) => {
    setValue(changedValue)
    clickHandler(changedValue)
  }

  return (
    <Grid item>
      {options.map((option) => (
        <MDButton
          key={option}
          sx={{ width: { xs: '100%', sm: 'auto' }, marginBottom: { xs: 1, sm: 0 }, ml: 1 }}
          variant={value === option ? 'contained' : 'outlined'}
          color={value === option ? 'error' : 'dark'}
          onClick={() => handleChange(option)}
        >
          {option}
        </MDButton>
      ))}
    </Grid>
  );
};

export default SelectGroup;